<template>
  <div class="settings-wrapper">
    <div class="settings">
      <email class="card" cardTitle="Billing" :tableData="billingList" :flag="1" @getDetail="getDetail" />
      <email class="card" cardTitle="Audit" :tableData="auditList" :flag="2" @getDetail="getDetail" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getSystemEmail } from "@/services/form"
import email from "./component/email.vue";
export default {
  name: "Settings",
  components: { email },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 9999,
      },
      billingList: [],
      auditList: [],
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  async created() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      this.loading = true
      await getSystemEmail( this.params ).then(res => {
        if(res.data){
          this.billingList = res?.data["billing"]
          this.auditList = res?.data["audit"]
        }
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>
<style scoped lang="scss">
.settings {
  padding:20px 20px; 
  background-color:#ffffff; 
  border-radius: 10px;
  height: calc(100vh - 50px - 20px - 20px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .card {
    flex: 1;
    overflow: hidden; 
  }
}
</style>
