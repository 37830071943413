<template>
  <div class="card" v-loading="loading">
    <div class="header flex justify-between">
      <span>{{ cardTitle }}</span>
    </div>
    <div class="content">
      <div class="form">
        <el-form :model="form" label-width="auto" :rules="rules" inline="true" size="mini" ref="formData">
            <el-form-item label="Email Recipient:" prop="email">
              <el-input class="email-input" v-model="form.email" clearable/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleAdd()">Add to List</el-button>
            </el-form-item>
            <el-form-item v-if="flag == '1'">
              <el-button type="primary" @click="handleSend()">Send Email</el-button>
            </el-form-item>
        </el-form>
      </div>
      <div class="Comtable" v-bind:class="{'mobile-view':isMobileScreen}">
        <el-table :data="tableData" 
            :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1'}"
            :cell-style="getCellStyle"
            style="width: 100%" 
            :show-header="true" 
            table-layout="auto"
          >
          <el-table-column prop="email" label="Email Address" width="500"  md:width="100" align="left"/>
          <el-table-column label="Action"  width="100" align="left">
              <tempate slot-scope="scope">
                <el-button type="primary" size="mini" plain @click="handleRemove(scope.row.id)">Remove</el-button>
            </tempate>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>
<script>
import { getSystemEmail,saveSystemEmail,removeSystemEmail,sendScheduledEmail } from "@/services/form"
import { mapState } from "vuex";
export default {
  name: "BillingEmail",
  props: {
    cardTitle: {
      type: String,
    },
    tableData: {
      type: Array,
    },
    flag:{
      type: String,
    }
  },
  data() {
    return {
      // tableData: [],
      getList: [],
      loading: false,
      searchValue: '',
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 9999,
      },
      form: {
        email: this.email,
      },
      rules: {
          email: [
            {required: true, message: 'Email cannot be empty', trigger: 'blur'},
            {required: true, transform: (values) => values && values.trim() ,message: 'Email cannot be empty', trigger: 'blur'},
            { type: 'email', message: 'Invalid email format', trigger: 'blur' }
          ],
      },
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  async created() {
    // await this.getDetail()
  },
  mounted() {
      this.$emit('save', this.save)
  },
  methods: {
    // async getDetail() {
    //   this.loading = true
    //   await getSystemEmail( this.params ).then(res => {
        
    //     this.tableData = res.data.table.rows
    //     this.total = res.data.table.total
    //   }).finally(() => {
    //     this.loading = false
    //   })
    // },
    async handleAdd() {
      this.loading = true;
      if (this.form.email) {
        this.form.email = this.form.email.trim();
      }
      const isValid = await new Promise((resolve) => {
        this.$refs.formData.validate((valid) => {
          if (!valid) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      });
      if (!isValid) {
        this.loading = false;
        return;
      }

      const data = {
        email: this.form.email,
        flag: this.flag
      };
      try {
        const res = await saveSystemEmail(data);
        this.$message.success('Add Email Success');
        this.$emit("getDetail");
      } catch (error) {
      } finally {
        this.loading = false;
        this.form.email = "";
      }
    },
    async handleRemove(id){
      this.loading = true
      await removeSystemEmail( id ).then(async res => {
        this.$message.success('Remove Email Success')
        this.$emit("getDetail");
      }).finally(() => {
        this.loading = false
      })
    },
    async handleSend() {
      this.loading = true;
      const data = {};
      try {
        const res = await sendScheduledEmail(data);
        this.$message.success('Send Email Success');
        this.$emit("getDetail");
      } catch (error) {
        // this.$message.success('Error saving email:' + error);
      } finally {
        this.loading = false;
      }
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  height: 50px;
  color: #4c565c;
  font-weight: 700;
  font-size: 15px;
}
.email-input{
  width: 250px;
}
.Comtable {
  width: 50%;
}
::v-deep .el-table {
  height: 30%;
  overflow: auto;
}
@media (max-width: 700px) {
  .Comtable {
    width: auto;
  }
  .email-input{
    width: 100%;
  }
}
::v-deep .el-table::before {
  height: unset;
}
</style>
